import React, { useState, useEffect } from 'react';
import bannerImage from './shopify.png'; // Your banner image path
import backgroundImage from './shopify.png'; // Background image path
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'; // Correct hook for navigation

const BannerWithBlur2 = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Set threshold for mobile devices (600px)
    };

    // Initialize on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Hide header and footer for this page instead of applying blur
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');

    if (header) {
      header.style.display = 'none'; // Remove the header
    }

    if (footer) {
      footer.style.display = 'none'; // Remove the footer
    }

    // Cleanup: Reset header and footer visibility when the page is unmounted or navigation happens
    return () => {
      if (header) {
        header.style.display = 'block'; // Show the header again
      }
      if (footer) {
        footer.style.display = 'block'; // Show the footer again
      }
    };
  }, []); // This will only run once when the BannerWithBlur component is mounted

  // Inline styles
  const styles = {
    bannerContainer: {
      position: 'relative',
      height: '100vh',
      width: '100%',
      overflow: 'hidden', // Ensure content does not overflow
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backgroundLayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${backgroundImage})`, // Add background image
      backgroundSize: 'cover', // Ensure the background image covers the container
      backgroundPosition: 'center', // Center the background image
      filter: 'blur(10px)', // Apply blur effect to the background only
      zIndex: 0, // Ensure it is behind the content
    },
    bannerContent: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1, // Make sure the content (bannerImage and CloseIcon) appear above the blurred background
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: isMobile ? '290px' : '', // Adjust width for mobile
    },
    bannerImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '5px',
      zIndex: 2, // Ensure the banner image appears above the blurred background
    },
    closeButton: {
      position: 'absolute',
      top: '1%',
      left: '0.6%',
      color: 'black', // Set text color to white
      borderRadius: '0%', // Make the button rounded
      padding: '8px', // Add some padding to make it bigger
      cursor: 'pointer', // Change the cursor to a pointer on hover
      display: 'flex', // Ensure the icon is centered
      justifyContent: 'center',
      alignItems: 'center',
    }
  };

  return (
    <div
      style={{
        width: '90vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative', // Ensure the content stays above the background layer
      }}
    >
      <div style={styles.backgroundLayer}></div> {/* Background layer with blur */}
      <div style={styles.bannerContainer}>
        <div style={styles.bannerContent}>
          <CloseIcon
            onClick={() => navigate('/')} // Using the useNavigate hook for navigation
            sx={styles.closeButton} // Apply custom styling for the close button
          />
          <img
            src={bannerImage}
            alt="Banner"
            style={{
              ...styles.bannerImage,
              width: 'auto', // Make sure width is auto to avoid stretching
              maxWidth: '100%', // Allow it to scale up if needed
              maxHeight: '90vh', // Limit height to prevent image overflow
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerWithBlur2;
